import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faGithub,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import classes from "./Footer.module.scss";

export default function Footer() {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img className="logo" src="/logo1.png" alt="logo" />
        <span>Handmade by me © 2022</span>
        <div className={classes.icons}>
          <a
            href="https://twitter.com/mpcausing"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>

          <a
            href="https://facebook.com/mpcausing"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://github.com/mpcausing"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            href="https://www.linkedin.com/in/mpcausing/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>
    </div>
  );
}
