import { NavLink } from "react-router-dom";
import classes from "./Header.module.scss";

export default function Header() {
  const Navs = [
    {
      to: "/",
      text: "About",
    },
    {
      to: "/projects",
      text: "Projects",
    },
    {
      to: "/contact",
      text: "Contact",
    },
  ];

  return (
    <div className={classes.root}>
      <img alt="logo" src="/logo2.png" />
      <div className={classes["nav-container"]}>
        {Navs.map((nav, index) => (
          <NavLink
            key={index}
            className={(navData) => (navData.isActive ? classes["active"] : "")}
            to={nav.to}
          >
            {nav.text}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
