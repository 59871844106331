import { NavLink } from "react-router-dom";
import { useTitle } from "react-use";
import classes from "./About.module.scss";

export default function About() {
  useTitle("Mark Phillip Causing - About Me");
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div>
          <img alt="logo" src="/avatar.jpg" />
        </div>
        <div className={classes.details}>
          <span className={classes.name}>Mark Phillip Causing</span>
          <span className={classes.position}>Software and Web Developer</span>
        </div>
      </div>
      <div className={classes.divider}></div>
      <span className={classes["additional-info"]}>
        Hi, I&apos;m Mark Phillip Causing and I&apos;m a person who loves to
        create beautifully organized websites and windows applications. I&apos;m
        really having fun learning new technologies and applying it on my
        projects. Currently I work primarily with{" "}
        <a
          href="https://reactjs.org/"
          target="_blank"
          className="external-link"
          rel="noreferrer"
        >
          React
        </a>{" "}
        and{" "}
        <a
          href="https://nodejs.org/"
          target="_blank"
          className="external-link"
          rel="noreferrer"
        >
          Node.js
        </a>
        .
        <br />
        <br />
        Have a look through some of my{" "}
        <NavLink className="external-link" to="/projects">
          projects
        </NavLink>
        .
      </span>
    </div>
  );
}
