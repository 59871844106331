import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Public from "Pages/Public/Public";
import About from "Pages/Public/About/About";
import Projects from "Pages/Public/Projects/Projects";
import Contact from "Pages/Public/Contact/Contact";

import Admin from "Pages/Admin";

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Public />}>
            <Route index element={<About />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects/:slug" element={<p>1</p>} />
            <Route path="contact" element={<Contact />} />
          </Route>

          {/* Admin Routes */}
          <Route path="admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
