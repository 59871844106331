import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import { Outlet } from "react-router-dom";

import classes from "./Public.module.scss";

export default function Public() {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Header />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
